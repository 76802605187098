import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {act_loadDepositUserHistory, act_loadDepositUserCurrent} from "../../../actions";
import { DepositUserCurrent } from './DepositUserCurrent';
import { DepositUserHistory } from './DepositUserHistory';

import "../../../css/DepositUser.css";

class InternalDepositUser extends React.Component {

  componentDidUpdate() {
    if (typeof(this.props.deposit_user.shouldRefresh) !== 'undefined' && this.props.deposit_user.shouldRefresh === true) {
      this.props.loadDepositUserHistory(this.props.user.id);
      this.props.loadDepositUserCurrent(this.props.user.id);
    }
  }

  render() {
    let deposit_history = this.props.deposit_user.history;
    let deposit_current = this.props.deposit_user.current;

    if (!deposit_history.datas || !deposit_current.datas) {
      return <div className='DepositUser text-center'><br />Chargement...</div>;
    }

    if (deposit_history.datas.count === 0) {
      return <div className='DepositUser'><br /><div className="alert alert-info">Le client n&apos;a commandé aucun produit consignable</div></div>;
    }

    return <div className='DepositUser'>
      <DepositUserCurrent deposit_current={deposit_current} />
      <hr />
      <DepositUserHistory deposit_history={deposit_history} user={this.props.user} />
    </div>;
  }
}

InternalDepositUser.propTypes = {
  "deposit_user": PropTypes.object,
  "user": PropTypes.object,
  "loadDepositUserHistory": PropTypes.func,
  "loadDepositUserCurrent": PropTypes.func,
};

export const DepositUser = connect(
  (state) => ({
    "deposit_user": state.support.deposit_user,
    "user": state.support.user
  }),
  (dispatch) => {
    return {
      loadDepositUserHistory: (id) => {
        return dispatch(act_loadDepositUserHistory(id));
      },
      loadDepositUserCurrent: (id) => {
        return dispatch(act_loadDepositUserCurrent(id));
      },
    };
  }
)(InternalDepositUser);
