import React from 'react';
import {Field, Formik} from 'formik';
import {Button, FormGroup, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {SUPPORT_USER_UPDATE} from "../../actions";
import {getObjectChanges} from "../../../../utils";
import {UserFraudDetail} from "./UserForm/UserFraudDetail";

class InternalUserForm extends React.Component {

  render() {
    const {user, validate } = this.props;
    if (!user || !user.id) return <span></span>;


    return <Formik
      initialValues={{...user}}
      onSubmit={(values, actions) => {
        validate(values, user)
      }}
      render={(props: FormikProps<Values>) => (
        <form onSubmit={props.handleSubmit} className="form-horizontal" style={{marginTop:"10px"}}>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label">Nom</label>
            <div className="col-sm-8">
              <Field type="text" name="last_name" required={true} className="form-control input-xs"/>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label">Prénom</label>
            <div className="col-sm-8">
              <Field type="text" name="first_name" required={true} className="form-control input-xs"/>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label">Email</label>
            <div className="col-sm-8">
              <Field type="text" name="email" required={true} className="form-control input-xs"/>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label">Numéro de tél.</label>
            <div className="col-sm-8">
              <Field type="text" name="phone_number" required={true} className="form-control input-xs"/>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="optin_email">Opt-in courriel</label>
            <div className="col-sm-8">
              <Field
                name="optin_email"
                id="optin_email"
                className="form-control"
                required="required"
                component="select"
              >
                <option key={2} value={2}>Oui</option>
                <option key={0} value={0}>Non</option>
                <option key={1} value={1}>Non défini</option>
              </Field>
              &nbsp;Accepte les courriels
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="optin_sms">Opt-in SMS</label>
            <div className="col-sm-8">
              <Field
                name="optin_sms"
                id="optin_sms"
                className="form-control"
                required="required"
                component="select"
              >
                <option key={2} value={2}>Oui</option>
                <option key={0} value={0}>Non</option>
                <option key={1} value={1}>Non défini</option>
              </Field>
              &nbsp;Accepte les SMS
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="optin_push">Opt-in push</label>
            <div className="col-sm-8">
              <Field
                name="optin_push"
                id="optin_push"
                className="form-control"
                required="required"
                component="select"
              >
                <option key={2} value={2}>Oui</option>
                <option key={0} value={0}>Non</option>
                <option key={1} value={1}>Non défini</option>
              </Field>
              &nbsp;Accepte les notifications
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="no_order_minimum_one_shot">No min one shot</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field type="checkbox" checked={props.values.no_order_minimum_one_shot} name="no_order_minimum_one_shot" id="no_order_minimum_one_shot" />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
                &nbsp;Prochaine commande sans min. de commande ni frais de port
              </label>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="without_shipping_fee_one_shot">Livraison gratuite <b>(extras exclus)</b> one shot</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field type="checkbox" checked={props.values.without_shipping_fee_one_shot} name="without_shipping_fee_one_shot" id="without_shipping_fee_one_shot" />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
                &nbsp;Prochaine commande sans frais de port <b>(extras exclus)</b>
              </label>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="without_extra_shipping_fee_oneshot">Frais de livraison <b>extras</b> gratuits one shot</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field type="checkbox" checked={props.values.without_extra_shipping_fee_oneshot} name="without_extra_shipping_fee_oneshot" id="without_extra_shipping_fee_oneshot" />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
                &nbsp;Prochaine commande avec frais de livraison <b>extras</b> gratuits
              </label>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="without_shipping_fee">Livraison gratuite (extras exclus)</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field type="checkbox" checked={props.values.without_shipping_fee} name="without_shipping_fee" id="without_shipping_fee" />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
              </label>
              <b style={{ marginLeft: '0.5rem' }}>Toutes les commandes</b>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="without_extra_shipping_fee">Frais de livraison extras gratuits</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field type="checkbox" checked={props.values.without_extra_shipping_fee} name="without_extra_shipping_fee" id="without_extra_shipping_fee" />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
              </label>
              <b style={{ marginLeft: '0.5rem' }}>Toutes les commandes</b>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="is_vip">Client VIP</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field type="checkbox" checked={props.values.is_vip} name="is_vip" id="is_vip" />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
              </label>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="super_venere">Client super vénère</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field
                  type="checkbox"
                  checked={!!props.values.super_venere_at}
                  name="super_venere"
                  id="super_venere"
                  onChange={(e) => {
                    if (e.target.checked && !props.values.super_venere_at) {
                      props.setFieldValue('super_venere_at', new Date())
                    } else if (!e.target.checked && props.values.super_venere_at) {
                      props.setFieldValue('super_venere_at', null)
                    }
                  }}
                />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
              </label>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="is_fraud">Fraudeur</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field type="checkbox" checked={props.values.is_fraud} name="is_fraud" id="is_fraud" />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
              </label>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="is_allowed_to_sponsor">Credit parrainage</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field type="checkbox" checked={props.values.is_allowed_to_sponsor} name="is_allowed_to_sponsor" id="is_allowed_to_sponsor" />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
              </label>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="is_fraud">Limite de commandes désactivée</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field type="checkbox" checked={props.values.is_bypass_limit} name="is_bypass_limit" id="is_bypass_limit" />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
                &nbsp;La limitation par commande ne sera pas effectuée pour ce client (quantité et périodicité).
              </label>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label" htmlFor="is_skip_fraud_detection">Détection Anti Fraude Désactivée</label>
            <div className="checkbox col-sm-8 super_checkbox">
              <label className="">
                <Field type="checkbox" checked={props.values.is_skip_fraud_detection} name="is_skip_fraud_detection" id="is_skip_fraud_detection" />
                <span className="checkbox_checked btn">Oui</span><span className="checkbox_not_checked btn">Non</span>
                &nbsp;La détection anti fraude ne sera pas effectuée pour ce client.
              </label>
            </div>
          </FormGroup>
          <FormGroup as={Row}>
            <label className="col-sm-4 control-label">Score de fraude</label>
            <span className="col-sm-4 checkbox"><UserFraudDetail user={user} /></span><span className="col-sm-4 checkbox"><a href={process.env.REACT_APP_DELEEVADMIN + `/fraud/details/${user.id}/`} rel="noopener noreferrer" title="Voir l'analyse de fraude" target="_blank">Analyse de fraude <i className="fa-solid fa-bullseye"></i></a></span>
          </FormGroup>
          <Button variant="primary" type="submit">
            Sauvegarder
          </Button>
        </form>
      )}
    />
  }
}


export const UserForm = connect(
  (state) => ({
    "user" : state.support.user
  }),
  (dispatch) => {
    return {
      "validate": (values, user) => {
        let patch = getObjectChanges(user, values);
        if (patch) {
          dispatch({"type": SUPPORT_USER_UPDATE, userId: values.id, patch: patch});
        }
      },

    };
  }
)(InternalUserForm);
