
import axios from 'axios';


export function extractToken() {
  const listCookies = window.document.cookie.split(';');
  for (let c of listCookies) {
    c = c.split('=');
    if (c[0] === 'api_token') {
      return c[1];
    }
  }
  return '';
}


// axios.defaults.baseURL = process.env.REACT_APP_DELEEVAPI;
// axios.defaults.headers.common['Authorization'] = 'Token ' + extractToken();
// axios.defaults.headers.common['Content-Type'] = 'application/json';

export const da = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_DELEEVAPI,
  timeout: 30000,
  headers: {
    'Authorization': 'Token ' + extractToken(),
    'Content-Type': 'application/json'
  }
});


// UTILS


let daUtil = {};

daUtil.req = function (method, url, data, config = {}) {
  return da.request({
    method:method,
    url: url,
    data: data,
    ...config
  }).then(response => response.data)
    .catch(err => {
      throw err;
    });
};

daUtil.post = function (url, data, config = {}) {
  return daUtil.req('post', url, data, config);
};

daUtil.patch = function (url, data, config = {}) {
  return daUtil.req('patch', url, data, config);
};

daUtil.get = function (url, config = {}) {
  return daUtil.req('get', url, null, config);
};

// metier


let api = {};


api.autoRefresh = function(version, centerId) {
  return da.get('/tasks/state/?version=' + version + '&center_id=' + centerId)
    .then(response => response.data)
    .catch(err  => {
      throw err;
    });
};


api.loadShippingTasks =  function (taskIds) {
  if (taskIds.length <= 0) {
    return [];
  }
  let taskIdsSerialized =  taskIds.join();
  return da.get('/tasks/shipping/?ids=' + taskIdsSerialized + '&compact=1')
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};


api.saveShippingTasks =  function (tasks, format) {
  //da.post('/tasks/shipping/simple_bulk/', tasks);
  let url = "/tasks/shipping/simple_bulk/";
  if(format === "dispatch"){
    url += "?dispatch=1";
  }
  return da.request({
    method: "post",
    url: url,
    data: tasks
  }).then(response => response.data)
    .catch(err => {
      throw err;
    });
};


api.shippingTaskToXdDock = taskId => {
  return da.get(`/tasks/shipping/${taskId}/assign_xd_dock/`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};


api.savePackingTasks =  function (tasks) {
  return da.post('/tasks/packing/simple_bulk/', tasks)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};


api.changeOrderStatus = function (
  orderId,
  status,
  serializer_type,
  additionalData,
) {
  // serializer_type = simple ou flat
  if (!serializer_type) {
    serializer_type = "simple";
  }
  let data = {
    "status": status,
    ...additionalData,
  };
  data[serializer_type] = true;
  return da.post(`/processing-orders/${orderId}/change_status/`, data)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.bulkChangeOrderStatus = (payload) => {
  return da.post(`/processing-orders/bulk_change_status/`, payload)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.destockAgain = function (orderId) {
  return da.post(`/orders/${orderId}/destock/`, {})
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};



api.editOrder = function (order) {
  order.flat = 1;
  return da.patch(`/processing-orders/${order.id}/`, order)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.processingOrder = function (service, data) {
  return da.post(`/processing-orders/${data.id}/${service}/`, data)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.getLateSmsContent = (orderId, smsTemplateId, creditsAmount) => {
  return da.get(`/processing-orders/${orderId}/late_sms_content/?sms_template_id=${smsTemplateId}&credits_amount=${creditsAmount}`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.ignoreLateOrder = orderId => {
  return da.get(`/processing-orders/${orderId}/process_late_order/`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.validateLateOrder = (orderId, data) => {
  return da.post(`/processing-orders/${orderId}/process_late_order/`, data)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.addOrderCrate = (orderId, crateCode, flat = null) => (
  da.post(`/processing-orders/${orderId}/add_crate/?ser=admin`, { crate_code: crateCode, flat })
    .then(response => response.data)
    .catch(err => {
      throw err;
    })
);

api.deleteOrderCrate = (orderId, crateCode, flat = null) => (
  da.post(`/processing-orders/${orderId}/delete_crate/?ser=admin`, { crate_code: crateCode, flat })
    .then(response => response.data)
    .catch(err => {
      throw err;
    })
);

api.loadDock = function (dock_id) {
  return da.get(`/docks/${dock_id}/`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.editDock = function (dock) {
  return da.patch(`/docks/${dock.id}/`, dock)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.closeDock = function (dock) {
  return da.delete(`/docks/${dock.id}/`, dock).then(r => r.data).catch((err) => { throw err; });
};

api.createStuartJob = function (dock, transportType) {
  throw Error('Non prevu pour le multi centre');
  /*
  return da.post(`/docks/${dock.id}/external_jobs/`, {"transport_type": transportType})
    .then(response => response.data)
    .catch(error => console.error(error));
  //*/
};

api.cancelStuartJob = function (dock) {
  return da.delete(`/docks/${dock.id}/external_jobs/`).then(r => r.data).catch(err => { throw err; });
};

api.getStuartJob = jobId => (
  da.get(`/processing-orders/get_external_job/?job_id=${jobId}`).then(r => r.data).catch(err => { throw err; })
);

api.getStuartEta = (orderId, vehicle) => (
  da.get(`/processing-orders/${orderId}/get_stuart_eta_to_center/?vehicle=${vehicle}`)
    .then(r => r.data)
    .catch(err => { throw err; })
);

api.callStuartForDock = (dockId, vehicle) => (
  da.post(`/dispatch/docks/${dockId}/call_stuart/`, { vehicle })
    .then(r => r.data)
    .catch(err => { throw err; })
);

api.callStuartForOrder = (orderId, vehicle) => (
  da.get(`/processing-orders/${orderId}/call_stuart/?vehicle=${vehicle}&flat=1`)
    .then(r => r.data)
    .catch(err => { throw err; })
);

api.callUberForOrder = (orderId) => (
  da.post(`/processing-orders/${orderId}/call_uber_direct/?flat=1`)
    .then(r => r.data)
    .catch(err => { throw err; })
);

api.cancelUberJob = function (dock) {
  return da.post(`/dispatch/docks/${dock.id}/cancel_uber_delivery/`).then(r => r.data).catch(err => { throw err; });
};

api.regularizeCanceledUber = dock => {
  return da.post(`/dispatch/docks/${dock.id}/regularize_canceled_uber/`)
    .then(response => response.data)
    .catch(error => console.error(error));
};

api.regularizeCanceledStuart = dock => {
  return da.post(`/docks/${dock.id}/regularize_canceled_stuart/`)
    .then(response => response.data)
    .catch(error => console.error(error));
};

// do not cleanup the task, but force all task in received
api.finishDock = function (dock) {
  return da.post(`/docks/${dock.id}/finish/`).then(r => r.data).catch(err => { throw err; });
};

api.dockManuallyOptimized = function (dock) {
  return (
    da
      .post(`/docks/${dock.id}/manually_optimized/`)
      .then(r => r.data)
      .catch(err => { throw err; })
  );
};

api.dockManuallyValidated = function (dock) {
  return (
    da
      .post(`/docks/${dock.id}/manually_validated/`)
      .then(r => r.data)
      .catch(err => { throw err; })
  );
};

api.xdLoadedDock = (dockId, extraCrates) => (
  da.post(`/docks/${dockId}/xd_loaded/`, { extra_crates: extraCrates }).then(r => r.data).catch(err => { throw err; })
);

api.startPresence = function (driverId, vehicle, centerId) {
  const d = {
    vehicle : vehicle,
    center_id: centerId,
    caller: "flow",
  };
  return da.post(`/drivers/${driverId}/start/`, d).then(r => r.data).catch(err => { throw err; });
};

api.stopPresence = function (driverId, unfinished_new_status) {
  return da.post(`/drivers/${driverId}/stop/?unfinished_new_status=${unfinished_new_status}`, {})
    .then(r => r.data).catch(err => { throw err; });
};

api.changeVehicle = function (driverId, vehicle) {
  return daUtil.post(`/drivers/${driverId}/vehicle/`, {vehicle : vehicle});
};

api.changeCenter = function (driverId, centerId) {
  return daUtil.post(`/drivers/${driverId}/change_center/`, {center_id: centerId});
};

api.getHiddenProductsToReturn = driverId =>
  daUtil.get(`/drivers/${driverId}/hidden_product_to_return`);

api.closeAlert = function(alert) {
  return daUtil.patch(`/alerts/${alert.id}/`, {is_read: true});
};


api.loadAlarm = function(centerId, lastOrderId, lastTs){
  return da.get('/load_alarm/?center=' + centerId + '&last_order_id=' + lastOrderId + '&last_ts=' + lastTs)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.loadArrivals = function(centerId) {
  return da.get(`/arrivals/?center=${centerId}`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.validateArrivals = function(arrivals) {
  return da.post(`/arrivals/`, { arrivals })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.loadDispatch = function(version, centerId, additionalParameters) {
  return da.get(`/dispatch/loadstate/?version=${version}&center_id=${centerId}${additionalParameters}`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.loadSlotsForOrder = function(postalCode, orderId) {
  return da.get(`/postal_codes/slots/?postal_code=${postalCode}&order_id=${orderId}`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.getUserAddresses = function(userId) {
  return da.get(`/admin-addresses/?user_id=${userId}`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.resetOrderForRedelivery = function(orderId, data) {
  return da.post(`/processing-orders/${orderId}/reset_for_redelivery/`, { ...data })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.orderToRestock = function(orderId, flagId = 1) {
  return da.get(`/processing-orders/${orderId}/to_restock/?flag_id=${flagId}`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.validateRestock = function(orderId) {
  return da.get(`/processing-orders/${orderId}/validate_restock/`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.getLastDriver = function(orderId) {
  return da.get(`/processing-orders/${orderId}/get_last_driver/`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.orderWasDelivered = function(orderId) {
  return da.post(`/processing-orders/${orderId}/was_delivered/`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.reviewLogTrolleyNotFound = function(id, payload) {
  return da.post(`/trolleys/${id}/not_found_review/`, payload)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.orderDirectDispatch = (orderId) => {
  return da.post(`/processing-orders/${orderId}/direct_dispatch/`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.ordersDirectDispatch = (orderIds, define) => {
  return da.post(
    `/docks/direct_dispatch_many/?order_ids=${orderIds.join(",")}&define=${define ? "1" : "0"}`
  )
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.listAllUberOrders = (centerId = null) => {
  return da.get(`/uber-eats/all-orders/?center_id=${centerId}`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.retrieveUberEatsStoreStatus = (centerId) => {
  return da.get(`/uber-eats/stores/${centerId}/status/`)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

api.updateUberEatsStoreStatus = (centerId, status) => {
  return da.put(`/uber-eats/stores/${centerId}/status/`, { status })
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
};

export { api, daUtil};
