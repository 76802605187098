import React from 'react';
import { useQuery } from 'react-query';
import {api} from "../../../api";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {extractCenterId} from "../../../utils/center";
import { Table, Badge } from "react-bootstrap";
import moment from 'moment';
import { useNumberOfBags } from '../../../hooks/useNumberOfBags';
import StoreStatusIndicator from './StoreStatusIndicator';
import ExternalShippingJobTooltip from '../../../common-comps/ExternalShippingJobsTooltip';

import '../css/UberEatsOrders.css';

const getStatusBackgroundColor = (status) => {
  if (status === 'received' || status === 'canceled') {
    return 'done';
  } else if (status === 'prepared' || status === 'shipping') {
    return 'prepared';
  } else if (status === 'prep') {
    return 'preparing';
  }
  return '';
};

const formatOrderStatus = (status) => {
  if (status === 'paid') {
    return "PAYÉE";
  } else if (status === 'prep') {
    return "EN PRÉP.";
  } else if (status === 'to-check') {
    return "À VÉRIF.";
  } else if (status === 'waiting-products') {
    return "ATTENTE PROD.";
  } else if (status === 'prepared') {
    return "FINALISÉE";
  } else if (status === 'shipping') {
    return "EN LIVRAISON";
  } else if (status === 'received') {
    return "LIVRÉE";
  } else if (status === 'canceled') {
    return "ANNULÉE";
  } else if (status === 'undeliveredclient') {
    return "PAS LÀ";
  } else if (status === 'blocked') {
    return "BLOQUÉE";
  } else if (status === 'stand-by') {
    return "STAND BY";
  }
  return status;
};

const formatVehicle = (vehicle) => {
  if (vehicle === 'BICYCLE') {
    return 'Vélo';
  } else if (vehicle === 'MOTORBIKE') {
    return 'Moto';
  } else if (vehicle === 'CAR') {
    return 'Voiture';
  } else if (vehicle === 'PEDESTRIAN') {
    return 'À pied';
  }
};

const formatDeliveryStatus = (status) => {
  if (status === 'SCHEDULED') {
    return 'Planifié';
  } else if (status === 'EN_ROUTE_TO_PICKUP') {
    return 'En chemin pour pickup';
  } else if (status === 'ARRIVED_AT_PICKUP') {
    return 'Arrivé au centre';
  } else if (status === 'EN_ROUTE_TO_DROPOFF') {
    return 'En livraison';
  } else if (status === 'COMPLETED') {
    return 'Livré';
  } else if (status === 'FAILED') {
    return 'Échec';
  }
  return status;
};

const BagCountSpan =  ({order}) => {
  const { getFormattedContent } = useNumberOfBags(order);

  return (<span>{getFormattedContent()}</span>);
};

const UberEatsOrders = ({centerId}) => {
  const { isLoading, isError, data, error }  = useQuery(
    ['uberEatsOrder', centerId],
    (_key, id) => api.listAllUberOrders(id),
    {
      enabled: !!centerId,
      refetchInterval: 10000, // 10secs
    });

  return (
    <>
      <StoreStatusIndicator />
      <h3>Commandes UberEats & B2C <small>(UberDirect)</small></h3>
      {isLoading && <div>Loading...</div>}
      {isError && <div>{error.message}</div>}
      {data && (
        <Table bordered hover size="sm">
          <thead style={{textAlign: 'center'}}>
            <tr>
              <th colSpan={6} style={{fontWeight: 'bold', fontSize: '1.7em'}}>
                Commande
              </th>
              <th colSpan={5} style={{fontWeight: 'bold', fontSize: '1.7em'}}>
                Uber
              </th>
            </tr>
            <tr>
              <th>
                Service
              </th>
              <th>
                Date
              </th>
              <th>
                ID
              </th>
              <th>
                Nom
              </th>
              <th>
                Statut
              </th>
              <th>
                Emplacement
              </th>
              <th>
                Sacs
              </th>
              <th>
                Nom de la commande
              </th>
              <th>
                Nom du livreur
              </th>
              <th>
                Num. du livreur
              </th>
              <th>
                Véhicule
              </th>
              <th>
                Statut de livraison
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map(o => (
              <tr key={o.id} className={`order-row ${getStatusBackgroundColor(o.order.status)}`}>
                <td>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    {o.uber_type === 'uber_eats' ? (<Badge style={{backgroundColor: '#06C167'}}>UberEats</Badge>) : null}
                    {o.uber_type === 'uber_direct' ? (<Badge style={{backgroundColor: 'black'}}>UberDirect</Badge>) : null}
                  </div>
                </td>
                <td>
                  {moment(o.created_time).format('LLL')}
                </td>
                <td>
                  <a
                    href={`/support/order-with-center/${o.order.id}/center/${centerId}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{o.order.id} (#{o.order.random_id})</a>
                </td>
                <td>
                  {o.order.fullname}
                </td>
                <td>
                  {formatOrderStatus(o.order.status)}
                </td>
                <td>
                  {o.order.transit_area}
                </td>
                <td>
                  <BagCountSpan order={o.order} />
                </td>
                <td>
                  <span style={{marginRight: '1rem'}}>{o.display_id}</span>
                  <ExternalShippingJobTooltip externalJobs={o.external_shipping_jobs} />
                </td>
                {o.delivery ? (
                  <>
                    <td>
                      {o.delivery.delivery_partner_name}
                    </td>
                    <td>
                      {o.delivery.delivery_partner_phone_number} ({o.delivery.delivery_partner_pin_code})
                    </td>
                    <td>
                      {formatVehicle(o.delivery.delivery_partner_vehicle_type)}
                      {o.delivery.delivery_partner_vehicle_plate && (
                        <span>
                          ({o.delivery.delivery_partner_vehicle_plate})
                        </span>
                      )}
                    </td>
                    <td>
                      {formatDeliveryStatus(o.delivery.status)}
                    </td>
                  </>
                ) : <td colSpan={4}>Commande en retrait</td>}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

UberEatsOrders.propTypes = {
  current: PropTypes.string,
  user: PropTypes.object,
  centerId: PropTypes.number,
  centers: PropTypes.array,
  location: PropTypes.object,
};

export const ConnectedUberEatsOrders = connect(
  (state) => {
    return {
      user : state.user,
      centers: (
        state.m.metas.centers
        || state.dispatch.metas.centers
        || []
      ),
      centerId: extractCenterId(state.user),
    };
  }
)(UberEatsOrders);

export default ConnectedUberEatsOrders;