import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";
import moment from 'moment';
import {withRouter} from "react-router-dom";

import "../../css/OrderList.css";
import {SUPPORT_CANCEL_SUBSCRIPTION} from "../../actions";


const InternalSubscriptionList = withRouter(({subscriptionList, cancelSubscription}) => {
  if (!subscriptionList) {
    return null;
  }
  moment.locale('fr');
  let createdDate = null;
  let dateLabel = null;

  return <div className='subscriptionList'>
    <Table size="sm" striped hover>
      <thead>
        <tr>
          <th>Id</th>
          <th>Produit</th>
          <th>Site</th>
          <th>Depuis le</th>
          <th>Statut</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {subscriptionList.map((subscription)=> {
          createdDate = moment(subscription.created);
          dateLabel = createdDate.format('L');
          return <tr key={subscription.id} className={""}>
            <td>#{subscription.id}</td>
            <td>{subscription.product_name}</td>
            <td>{subscription.site_name}</td>
            <td>{dateLabel}</td>
            <td>{subscription.status}</td>
            <td>
              {
                subscription.status === 'canceled'
                ? null
                : <button
                  className={"btn btn-sm btn-danger"}
                  onClick={() => cancelSubscription(subscription)}
                >
                  Annuler l'abonnement
                </button>
              }
            </td>
          </tr>;
        })}
      </tbody>
    </Table>
  </div>;
});

InternalSubscriptionList.propTypes = {
  subscriptionList: PropTypes.array,
  cancelSubscription: PropTypes.func,
};

export const SubscriptionList = connect(
  null,
  (dispatch) => {
    return {
      cancelSubscription: (subscription) => {
        dispatch(
          {
            type: SUPPORT_CANCEL_SUBSCRIPTION,
            subscriptionId: subscription.id,
          }
        );
      },
    }
  },
)(InternalSubscriptionList);
