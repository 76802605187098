import React from 'react';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import { api } from "../../../api";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { extractCenterId } from "../../../utils/center";
import moment from 'moment';

const formatStoreStatus = (status) => {
  if (status === 'ONLINE') {
    return "OUVERT";
  } else if (status === 'OFFLINE') {
    return "EN PAUSE";
  }
  return status;
};

const formatStoreStatusReason = (reason) => {
  if (reason === 'PAUSED_BY_RESTAURANT') {
    return "Mis en pause par le restaurant";
  } else if (reason === 'OUT_OF_MENU_HOURS') {
    return "En dehors des heures d'ouverture";
  } else if (reason === 'PAUSED_BY_UBER') {
    return "Mis en pause par Uber";
  }
  return reason;
};

const formatStoreStatusMetadata = (metadata) => {
  if (metadata === 'UNKNOWN') {
    return "Inconnue";
  } else if (metadata === 'PROBLEM_IN_RESTAURANT') {
    return "Problème dans le restaurant";
  } else if (metadata === 'OUT_OF_ITEM') {
    return "En rupture de stock";
  } else if (metadata === 'CLOSING_EARLY') {
    return "Fermeture anticipée";
  } else if (metadata === 'INCIDENT_DETECTED_ON_ORDER_MANAGEMENT_APPLICATION') {
    return "Incident détecté sur l'application de gestion des commandes";
  } else if (metadata === 'UNFULFILL_RATE') {
    return "Taux d'acceptation non-respecté (automatique après un refus/annulation)";
  } else if (metadata === 'NO_COURIER_AVAILABLE') {
    return "Pas de livreur disponible";
  } else if (metadata === 'FULFILLMENT_LATENCY') {
    return "Délai de préparation";
  } else if (metadata === 'PCA_LATENCY') {
    return "Délai PCA";
  } else if (metadata === 'PCA_ERROR_RATE') {
    return "Taux d'erreur PCA";
  } else if (metadata === 'INVALID') {
    return "Invalide";
  } else if (metadata === 'OTHER') {
    return "Autre";
  }
  return metadata;
};

const StoreStatusIndicator = ({ centerId }) => {
  const queryCache = useQueryCache();
  const { isError, data: storeStatusData, error, refetch } = useQuery(
    ['uberEatsStoreStatus', centerId],
    (_key, id) => api.retrieveUberEatsStoreStatus(id),
    {
      enabled: !!centerId,
      refetchInterval: 5 * 60000, // 5 mins
      retry: 5,
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    });
  const [
    handleUpdateStoreStatus,
    { isError: mutateStoreStatusIsError, error: mutateStoreStatusError }
  ] = useMutation((status = 'ONLINE') => {
    return api.updateUberEatsStoreStatus(centerId, status);
  }, {
    onSuccess: () => {
      queryCache.invalidateQueries({ queryKey: ['uberEatsStoreStatus', centerId] });
      refetch();
    },
    onError: (error) => {
      console.error(error);
    }
  });

  if (!storeStatusData) {
    return null;
  }

  return (
    <>
      <h1><span className={`badge badge-${storeStatusData.status === 'OFFLINE' ? 'danger' : 'success'}`}>{formatStoreStatus(storeStatusData.status)}</span></h1>
      {storeStatusData.status === 'OFFLINE' && (
        <div className="alert alert-danger" role="alert">
          <h4 className="alert-heading" style={{ backgroundColor: '#f2dede' }}>Le restaurant est en pause.</h4>
          <hr />
          Il n'est pas possible de recevoir des commandes UberEats.<br />
          {storeStatusData.is_offline_until && (
            <>
              Jusqu'à: {moment(storeStatusData.is_offline_until).locale('fr').format('LLLL')}<br />
            </>
          )}
          Raison: {formatStoreStatusReason(storeStatusData.offline_reason)}
          {storeStatusData.offline_reason_metadata && (
            <span>
              ({formatStoreStatusMetadata(storeStatusData.offline_reason_metadata)})
            </span>
          )}<br />
          {storeStatusData.offline_reason !== 'OUT_OF_MENU_HOURS' ? (
            <div>
              Pour le rouvrir, veuillez cliquer sur le bouton ci-dessous.<br />
              <button className="btn btn-success" onClick={() => handleUpdateStoreStatus()}>Rouvrir le restaurant</button>
            </div>
          ) : (
            <div>
              Les heures d'ouverture sont configurables <a href={process.env.REACT_APP_DELEEVADMIN + `/admin/slots/byweekdayrule/?center=${centerId}`}
                target="_blank" rel="noopener noreferrer">ici</a>. (Il faudra ensuite faire une synchronisation manuelle des stores sur <a href={process.env.REACT_APP_PRODUCTS + '/sites'}
                target="_blank" rel="noopener noreferrer">products</a> pour appliquer les nouveaux horaires)
            </div>
          )}
        </div>
      )}
      {isError && <div>{error.message}</div>}
      {mutateStoreStatusIsError && <div>{mutateStoreStatusError.message}</div>}
    </>
  );
};

StoreStatusIndicator.propTypes = {
  current: PropTypes.string,
  user: PropTypes.object,
  centerId: PropTypes.number,
  centers: PropTypes.array,
  location: PropTypes.object,
};

export const ConnectedStoreStatusIndicator = connect(
  (state) => {
    return {
      user: state.user,
      centers: (
        state.m.metas.centers
        || state.dispatch.metas.centers
        || []
      ),
      centerId: extractCenterId(state.user),
    };
  }
)(StoreStatusIndicator);

export default ConnectedStoreStatusIndicator;