import React from 'react';
import moment from "moment";
import 'moment/locale/fr';
import PropTypes from "prop-types";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";

moment.locale('fr');

const LGEP_SITE_ID = 1012;
const FRICHTI_SITE_ID = 1013;
const FRICHTICO_SITE_ID = 1015;


export const TagsOrder = ({ order, address, client }) => {
  const openChronopostPrint = (order) => {
    if (order) {
      window.open(`${process.env.REACT_APP_DELEEVADMIN}/logistics/chronopost/print?order_id=${order.id}`, 'win_chrono', 'height=700,width=800,menubar=yes,location=yes,resizable=yes');
    }
  };

  let weight = parseFloat(order.total_weight.replace('Kg', '').trim());
  weight = Math.round(weight);

  let a = address || order.shipping_address;

  let frozen_icon = null;
  if (order.pseudo_zones_status) {
    for (var pz_idx in order.pseudo_zones_status) {
      let opz = order.pseudo_zones_status[pz_idx];
      let pz = opz.pseudozone;
      if (
        pz
        && pz.is_active
        && pz.is_packing_on_expe
        && opz.count_item_match > 0
      ) {
        let classNames = "frozen-icon fa-solid fa-snowflake";
        let tooltip = null;
        if (opz.is_locked && typeof (opz.user.id) !== "undefined") {
          classNames += " doing";
          let started_at = moment(opz.locked_at);
          tooltip = (
            <Tooltip id={`tooltip-frozen-icon-${opz.id}`}>
              {opz.user.name} depuis {started_at.hour()}h{started_at.format("mm")}<br />
              Préparation: {opz.is_packing_done ? <span>Fait</span> : <span>En cours</span>}<br />
              Mise en chariot: {opz.is_packing_done ? <span>En cours</span> : <span>À faire</span>}<br />
            </Tooltip>
          );
        } else if (opz.is_done && typeof (opz.user.id) !== "undefined") {
          classNames += " done";
          let done_at = moment(opz.done_at);
          tooltip = (
            <Tooltip id={`tooltip-frozen-icon-${opz.id}`}>
              Fait par {opz.user.name} depuis {done_at.hour()}h{done_at.format("mm")}<br />
            </Tooltip>
          );
        }
        frozen_icon = <i className={classNames} title="Commande avec du Surgelé"></i>;
        if (tooltip) {
          frozen_icon = (
            <OverlayTrigger placement="top" overlay={tooltip} transition={false} className="TooltipFrozenIcon">
              {frozen_icon}
            </OverlayTrigger>
          );

        }
        break;
      }
    }
  }

  return <span>
    {order.tags.includes('chronopost') && <button className="btn btn-xs btn-primary" onClick={() => openChronopostPrint(order)} >Chrono</button>}
    {weight > 1 && <Badge className={"weight" + (weight > 50 ? " heavy" : "")}>{weight} k</Badge>}
    {order.is_pro && <Badge className="is-pro">PRO</Badge>}
    {order.tags.includes('first') && <Badge variant="danger" className="first-cmd">FIRST</Badge>}
    {
      (order.tags.includes('dqp_courier')
        ? <Badge variant="danger" className="courier">BOLT</Badge>
        : order.tags.includes('dqp_express') && !order.tags.includes('dqp_express_prio')
          ? <Badge variant="danger" className="express">Express</Badge>
          : null
      )
    }
    {order.tags.includes('op_ukr') && <Badge variant="danger" className="express">UKR</Badge>}
    {
      client && client.is_vip
        ? <Badge variant="danger"><b><u><i className="fa-solid fa-certificate"></i> VIP</u></b></Badge>
        : order.is_important
          ? <Badge variant="warning"><b>!!</b></Badge>
          : null
    }
    {order.paid_with_tr && <Badge className="is-tr">TR</Badge>}
    {order.option_tranquility && <Badge className="is-ot"><span className="fa-solid fa-volume-xmark"></span></Badge>}
    {order.cross_docking && <Badge variant="info"><span title="Commande Cross Docking" className="fa-solid fa-shuffle"></span></Badge>}
    {order.is_short_shipping_slot && <Badge variant="danger" title="creneau court" className="express">CC</Badge>}
    {order.is_uber_eats_order && <Badge title="ubereats" className="ubereats">UB</Badge>}
    {order.canteen_id && <Badge title="Commande cantine" variant="default"><span className="fa-solid fa-utensils"></span><strong style={{ marginLeft: 3 }}>CTN {moment(order.canteen_cutoff).format('HH:mm')}</strong></Badge>}
    {(a === undefined || !a.latitude || !order.estimated_travel_departure_at) && <Badge style={{ background: "red", color: "white" }} title={"Adresse invalide"}><i className="fa-solid fa-map-location-dot" title="Adresse invalide"></i> ERREUR</Badge>}
    {frozen_icon}
    {order.is_super_venere && <Badge className="is-super-venere"><strong title="Client super vénère"><i className="fa-solid fa-face-angry"></i>VENERE</strong></Badge>}
    {order.tags.includes('missing_bags') && <Badge className="missing-bags"><strong title="sacs oubliés"><i className="fa-solid fa-bag-shopping"></i> Livr. sacs oubliés</strong></Badge>}
    {order.tags.includes('redelivering') && !order.tags.includes('missing_bags') && <Badge className="redelivering"><strong title="relivraison">Relivraison</strong></Badge>}
    {order.site_id === LGEP_SITE_ID && <Badge className="lgep"><strong title="La Grande Épicerie de Paris chez Vous">GEP</strong></Badge>}
    {order.site_id === FRICHTI_SITE_ID && <Badge className="frichti"><strong title="FRICHTI">FRICHTI</strong></Badge>}
    {order.site_id === FRICHTICO_SITE_ID && order.canteen_id === null && <Badge className="uberdirect"><strong title="UD">UD</strong></Badge>}
    {order.codepromo && order.codepromo.toUpperCase() === 'GOBLAST' && <Badge className="goblast"><strong title="GOBLAST">GOBLAST</strong></Badge>}
  </span>;
};

TagsOrder.propTypes = {
  order: PropTypes.object.isRequired,
  address: PropTypes.object,
  client: PropTypes.object,
};
